import React, { useState } from "react";
import { Box, Typography, Button, Modal, Link } from "@mui/material";
import Layout from "../../components/Layout";
import { MetaData } from "../../components/common/meta";
import { PageProps } from "gatsby";
import bricks from "../../images/connect/bricks.svg";
import mesh from "../../images/mesh.svg";
import Arrow from "../../images/arrow-right.inline.svg";
import { Link as RouterLink } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import { StaticImage } from "gatsby-plugin-image";
import { TwitterTweetEmbed } from "react-twitter-embed";

import simpleLine from "../../images/simpleLine.svg";
import sideBrand2 from "../../images/pitLine2.svg";
import sideBrand from "../../images/firedanceLine.svg";
import lines from "../../images/brandLines.svg";
import bricks2 from "../../images/career/bricks.svg";

import networking from "../../images/networking.svg";
import Play from "../../images/play.inline.svg";
import Play2 from "../../images/play2.inline.svg";
import Pdf from "../../images/pdf.inline.svg";
import CloseIcon from "../../images/x.inline.svg";
import Github from "../../images/github2.inline.svg";
import Twitter from "../../images/twitter.inline.svg";
import ArrowSquare from "../../images/firedancer/arrow.inline.svg";

export const projectBox = {
  textAlign: "center",
  background: "#091A3D",
  border: "1px solid #4B608D",
  position: "relative",
  transition: "250ms cubic-bezier(0.4, 0, 0.2, 1)",
  minHeight: "105px",
  display: "flex",
  flexDirection: "column",
  p: 1.5,
  alignItems: "center",
  justifyContent: "center",
  "&:before": {
    content: '""',
    position: "absolute",
    width: "100%",
    height: "100%",
    background: "#091A3D",
    border: "1px solid #4B608D",
    left: "10px",
    top: "-10px",
    zIndex: -1,
    transition: "250ms cubic-bezier(0.4, 0, 0.2, 1)",
  },
  "& span": {
    position: "absolute",
    width: "100%",
    height: "100%",
    background: "#091A3D",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 1,
    opacity: "0",
    transition: ".3s ease-in-out",
  },
  "&:hover span": {
    opacity: 1,
  },
};

export const projectBoxLogo = {
  display: "block",
  maxHeight: "44px",
  maxWidth: "110px",
  width: "auto",
  marginBottom: "11px",
};

const videobox = {
  position: "relative",
  cursor: "pointer",
  border: "1px solid #4B608D",
  backgroundColor: "#0C1F47",
  transition: ".3s ease-in-out",
  mb: { xs: 1, md: 0 },
  "&:before": {
    content: '""',
    position: "absolute",
    width: "100%",
    height: "100%",
    left: "10px",
    top: "-10px",
    border: "1px solid #4B608D",
    background: "#0C1F47",
    zIndex: "-1",
  },
  "& svg": {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    transition: ".3s ease-in-out",
  },
  "& img": {
    display: "block",
    width: "100%",
  },
  "&:hover": {
    borderColor: "#1CE7C2",
  },
  "&:hover svg": {
    transform: "translate(-50%, -50%) scale(1.05)",
  },
};

const tick = {
  position: "absolute",
  backgroundColor: "#1CE7C2",
  color: "#0D2352",
  fontSize: 12,
  lineheight: "24px",
  zIndex: 2,
  px: 1,
  py: 0.8,
  top: "2em",
  minWidth: "65px",
  left: 0,
  textAlign: "center",
};

const modalStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  p: "20px",
};
const videoStyle = {
  backgroundColor: "rgba(0,0,0,1)",
  width: "100%",
  maxWidth: 1000,
  maxheight: "90%",
  position: "relative",
  boxShadow: "0 0 30px 0 rgba(0,0,0,2)",
};

const impossible = ({ location }: PageProps) => {
  const [open, setOpen] = useState(false);
  const [videoID, setVideoID] = useState({
    video: "",
    platform: "",
  });
  const handleOpen = (video: string, platform: string) => {
    setVideoID({
      video: video,
      platform: platform,
    });
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setVideoID({
      video: "",
      platform: "",
    });
  };

  const twitterUrl = "https://twitter.com/jump_firedancer";
  const githubUrl = "https://github.com/firedancer-io/firedancer";

  return (
    <Layout>
      <MetaData title="Firedancer" location={location} type="website" />

      <Box
        component="span"
        sx={{
          position: "absolute",
          left: "65px",
          top: "200px",
          height: "calc(100% - 415px)",
          width: 0,
          borderLeft: "1px solid #4B608D",
          display: { xs: "none", xl: "block" },
          zIndex: -1,
          "&:before": {
            content: '""',
            position: "absolute",
            top: "-5px",
            left: "-8px",
            width: "15px",
            height: "5px",
            backgroundColor: "#1CE7C2",
          },
        }}
      />
      <Box component="div" sx={{ position: "relative" }}>
        <Box
          component="img"
          src={bricks}
          sx={{
            position: "absolute",
            left: 0,
            width: "100%",
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: -1,
            pointerEvents: "none",
          }}
        />

        <Box
          component="div"
          sx={{
            maxWidth: 963,
            mx: "auto",
            mt: { xs: 12, md: 20 },
            mb: { xs: 6, md: 12 },
            textAlign: "center",
          }}
        >
          <Typography sx={{ mb: 3 }} variant="h1">
            {" "}
            <Box component="span" sx={{ color: "#1CE7C2" }}>
              A Second
            </Box>{" "}
            Solana Validator
          </Typography>
          <Typography sx={{ maxWidth: 730, mx: "auto", fontSize: 16 }}>
            We are building a new validator client for Solana to increase its networking throughput, resilience, and efficiency.{" "}
          </Typography>
          <Button
            component={RouterLink}
            to={githubUrl}
            variant="outlined"
            endIcon={<Github />}
            sx={{ mt: 3, zIndex: 2 }}
          >
            .Explore on GitHub
          </Button>
        </Box>
      </Box>
      <Box
        component="img"
        src={mesh}
        sx={{ width: "100%", mt: "-100px", transform: "scale(1.12)" }}
      />

      <Box
        component="div"
        sx={{
          position: "relative",
          pt: { xs: 10, md: 10, xl: 10 },
        }}
      >
       
      </Box>

      <Box
        component="span"
        sx={{
          position: "relative",
          "&:before": {
            content: `url(${sideBrand2})`,
            position: "absolute",
            left: 50,
            top: -130,
            display: { xs: "none", xl: "block" },
            zIndex: -1,
          },
        }}
      />
      <Box
        component="div"
        sx={{
          maxWidth: 1082,
          display: "flex",
          flexWrap: "nowrap",
          flexDirection: { xs: "column", sm: "row" },
          mx: "auto",
          mb: { xs: 10, md: 14 },
        }}
      >
        <Box
          component="div"
          sx={{
            mb: 4,
            alignSelf: { xs: "center", sm: "initial" },
            textAlign: { xs: "center", sm: "left" },
            mr: { xs: 0, sm: "auto" },
          }}
        >
          <Typography
            variant="h2"
            component="h3"
            sx={{ maxWidth: 310, mb: 2.4 }}
          >
            Media{" "}
            <Box component="span" sx={{ color: "#1CE7C2" }}>
              Mentions_
            </Box>
          </Typography>
          <Typography sx={{ maxWidth: "200px" }}>
            Read about Firedancer in the news.
          </Typography>
        </Box>
        <Box
          component="div"
          sx={{
            mb: 4,
            alignSelf: { xs: "initial", sm: "end" },
            px: { xs: 1, sm: 1, md: 1.3 },
            flex: { xs: "0 1 100%", sm: "0 1 230px" },
          }}
        >
          <Link
            component={OutboundLink}
            href="https://www.alchemy.com/overviews/what-is-firedancer"
            target="_blank"
            rel="noreferrer"
            sx={projectBox}
          >
            <StaticImage
              src="../../images/firedancer/alchemy.png"
              placeholder="blurred"
              alt="Alchemy"
              loading="lazy"
              style={{ objectFit: "cover", marginBottom: 11 }}
            />
            <Typography sx={{ color: "#1CE7C2" }}>January 20, 2023</Typography>
            <Box component="span">
              <ArrowSquare />
              .Read more
            </Box>
          </Link>
        </Box>
        <Box
          component="div"
          sx={{
            mb: 4,
            alignSelf: { xs: "initial", sm: "end" },
            px: { xs: 1, sm: 1, md: 1.3 },
            flex: { xs: "0 1 50%", sm: "0 1 230px" },
          }}
        >
          <Link
            component={OutboundLink}
            href="https://fortune.com/crypto/2022/08/16/jump-crypto-to-build-solana-software-a-second-and-new-validator-client/"
            target="_blank"
            rel="noreferrer"
            sx={projectBox}
          >
            <StaticImage
              src="../../images/firedancer/fortune.png"
              placeholder="blurred"
              alt="Fortune"
              loading="lazy"
              style={{ objectFit: "cover", marginBottom: 11 }}
            />
            <Typography sx={{ color: "#1CE7C2" }}>August 16, 2022</Typography>
            <Box component="span">
              <ArrowSquare />
              .Read more
            </Box>
          </Link>
        </Box>
        <Box
          component="div"
          sx={{
            mb: 4,
            alignSelf: { xs: "initial", sm: "end" },
            px: { xs: 1, sm: 1, md: 1.3 },
            flex: { xs: "0 1 50%", sm: "0 1 230px" },
          }}
        >
          <Link
            component={OutboundLink}
            href="https://decrypt.co/107521/jump-crypto-plans-solana-validator-client-boost-performance-decentralization"
            target="_blank"
            rel="noreferrer"
            sx={projectBox}
          >
            <StaticImage
              src="../../images/firedancer/decrypt.png"
              placeholder="blurred"
              alt="Fortune"
              loading="lazy"
              style={{ objectFit: "cover", marginBottom: 11 }}
            />
            <Typography sx={{ color: "#1CE7C2" }}>August 16, 2022</Typography>
            <Box component="span">
              <ArrowSquare />
              .Read more
            </Box>
          </Link>
        </Box>
      </Box>

   


      <Box
        component="img"
        src={lines}
        sx={{
          position: "absolute",
          right: "0",
          bottom: "0",
          zIndex: -1,
          pointerEvents: "none",
        }}
      />

  
    </Layout>
  );
};

export default impossible;
